<template>
  <div>

    <modal :show.sync="syncShow" max-width-class="tw-max-w-[809px]" headerClass="tw-bg-[#E1EDFE]">
      <template #header>
        <div class="tw-bg-[#E1EDFE] tw-h-[75px] tw-flex tw-items-center">
          <span class="tw-text-[18px] tw-text-[#1C2C40] tw-flex-1 tw-ml-[28px]">{{ $gettext('ALQUILER DE MAQUINARIA') }}</span>
          <div class="tw-flex tw-h-32 justify-between tw-items-start tw-p-5 tw-rounded-t ">
          <button @mousedown="close" type="button" class="tw-absolute tw-right-2 tw-text-gray-400  tw-rounded-lg tw-text-sm tw-p-1.5 tw-mt-4 tw-ml-auto tw-inline-flex tw-items-center" data-modal-toggle="defaultModal">
            <svg xmlns="http://www.w3.org/2000/svg" width="14.428" height="14.129" viewBox="0 0 14.428 14.129">
              <g id="Grupo_1102" data-name="Grupo 1102" transform="translate(1.414 1.415)">
                <g id="Grupo_586" data-name="Grupo 586" transform="translate(0 0)">
                  <line id="Line_60_1_" y1="5.7" x2="5.6" transform="translate(0 5.6)" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                  <line id="Line_61_1_" x2="5.6" y2="5.6" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                </g>
                <g id="Grupo_587" data-name="Grupo 587" transform="translate(11.6 11.3) rotate(180)">
                  <line id="Line_60_1_2" data-name="Line_60_1_" y1="5.7" x2="5.6" transform="translate(0 5.6)" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                  <line id="Line_61_1_2" data-name="Line_61_1_" x2="5.6" y2="5.6" fill="none" stroke="#00adfc" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2"/>
                </g>
              </g>
            </svg>
          </button>
        </div>
        </div>        
      </template>  
      <template #body>
        <div class="tw-m-[28px]">
            <p class="tw-text-[14px]">
                {{ $gettext('Si estás haciendo un pedido de alquiler de maquinaria, es obligatorio completar los datos de este formulario.') }}
                <br />
                {{ $gettext('En caso contrario puedes cerrar esta ventana') }}
            </p>
            <div class="row tw-mt-[26px]">
                <div class="col-md-4 tw-flex tw-flex-col">
                    <label class="tw-text-[13px] tw-font-normal">{{ $gettext('Tipo de cliente') }}</label>
                    <div class="dropdown Select-bk !tw-w-full !tw-ml-0">
                        <button class="form-control btn btn-default dropdown-toggle"  
                        type="button" id="ddLeadCommercial" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            {{client_type ? client_type : ''}}
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="ddLeadCommercial">
                            <li v-for="(item, index) in typesClient" :key="index" @click="client_type = item.name" :class="{'disabled': client_type && client_type == item.name}">
                                <span>{{item.name}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="tw-text-[13px] tw-font-normal">{{ $gettext('Trabajo a realizar') }}</label>
                    <div class="dropdown Select-bk !tw-w-full !tw-ml-0">
                        <button class="form-control btn btn-default dropdown-toggle" 
                        type="button" id="ddLeadCommercial" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            {{work_type ? work_type : ''}}
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="ddLeadCommercial">
                            <li v-for="(item, index) in typesTask" :key="index" @click="work_type = item.name" :class="{'disabled': work_type && work_type == item.name}">
                                <span>{{item.name}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <label class="tw-text-[13px] tw-font-normal">{{ $gettext('Tipo de maquinaria') }}</label>
                    <div class="dropdown Select-bk !tw-w-full !tw-ml-0">
                        <button class="form-control btn btn-default dropdown-toggle" 
                         type="button" id="ddLeadCommercial" data-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                            {{machinery_type ? machinery_type : ''}}
                            <i class="fa fa-angle-down" aria-hidden="true"></i>
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="ddLeadCommercial">
                            <li v-for="(item, index) in typesMachine" :key="index" @click="machinery_type = item.name"  :class="{'disabled': machinery_type && machinery_type == item.name}">
                                <span>{{item.name}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row tw-mb-[8px]">
                <div class="col-md-4 tw-flex tw-flex-col">
                    <label class="tw-text-[13px] tw-font-normal">{{ $gettext('Localización del Alquiler  - Ciudad') }}</label>
                    <input type="text" class="form-control" :placeholder="$gettext('Ciudad')" v-model="city">
                </div>
                <div class="col-md-4">
                    <label class="tw-text-[13px] tw-font-normal">{{ $gettext('Localización de al Alquiler - Provincia') }}</label>
                    <input type="text" class="form-control" :placeholder="$gettext('Provincia')" v-model="province">
                </div>
                <div class="col-md-4">
                    <label class="tw-text-[13px] tw-font-normal">{{ $gettext('¿Quién utilizará la máquina?') }}</label>
                    <input type="text" class="form-control" v-model="machinery_use">
                </div>
            </div>
            <div class="row">
                <div class="col-md-4 tw-flex tw-flex-col">
                    <label class="tw-text-[13px] tw-font-normal">{{ $gettext('Fecha de solicitud') }}</label>
                    <date-picker class="calendar-cn !tw-w-full !tw-min-w-[100%]" style="margin-left:0" :lang="pickupDate.lang" :format="pickupDate.format" type="day" 
                    :clearable="pickupDate.clearable" :shortcuts="pickupDate.shortcuts" v-model="request_date">

                    </date-picker>

                </div>
                <div class="col-md-4">
                    <label class="tw-text-[13px] tw-font-normal">{{ $gettext('Fecha inicio alquiler') }}</label>
                    <date-picker class="calendar-cn !tw-w-full !tw-min-w-[100%]" style="margin-left:0" :lang="pickupDate.lang" :format="pickupDate.format" type="day" 
                    :clearable="pickupDate.clearable" :shortcuts="pickupDate.shortcuts" v-model="rent_date">

                    </date-picker>
                </div>
                <div class="col-md-4">
                    <label class="tw-text-[13px] tw-font-normal">{{ $gettext('Días de alquiler') }}</label>
                    <div class="tw-flex tw-items-center tw-border tw-border-[#E2E2E2] tw-h-[34px] tw-text-[#1592E6]">
                        <button @click="rent_days = rent_days - 1 > 0 ? rent_days - 1 : rent_days" class="tw-border-r tw-border-[#E2E2E2] tw-w-[41px] tw-h-full">-</button>
                        <input type="number" placeholder="0" min="0" v-model="rent_days" class="tw-text-center">

                        <button @click="rent_days++" class="tw-border-l tw-border-[#E2E2E2] tw-w-[41px] tw-h-full">+</button>
                    </div>
                </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                    <label class="tw-text-[13px] tw-font-normal">{{ $gettext('Observaciones') }}</label>
                    <textarea v-model="description" class="form-control"></textarea>
                </div>
            </div>
        </div>

      </template>
  
      <template #footer>
        <div class="tw-flex tw-items-center tw-justify-center tw-gap-4 tw-pt-[24px] tw-pb-[34px] tw-border-t tw-border-[#ABC7F1] tw-m-[28px]">
            <button class="btn btn-cancel" @click="close">{{ $gettext('Cancelar') }}</button>
            <button class="btn btn-action" @click="finish">{{ $gettext('Enviar') }}</button>

        </div>

      </template>
    </modal>
  </div>
  </template>
  
  <script>
  import DatePicker from 'vue2-datepicker'
  import appConfig from '@/config-app.js'
  import { EventBus } from '@/mixins/eventBus.js';
  import SrvOrders from '@/services/orders'

  import Modal from '@/components/common/Modal_v2.vue'
  export default {
    name: "Modal_alquiler",
    components: { Modal, DatePicker},
    props: {
      show: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        syncShow: false,
        client_name: null,
        center_name: null,
        city: null,
        province: null,
        machinery_use: null,
        client_type: null,
        work_type: null,
        machinery_type: null,
        request_date: null,
        rent_date: null,
        rent_days: null,
        description: null,
        typesClient: [
            {id: 'empresa-de-limpieza', name: this.$gettext('Empresa de limpieza')},
            {id: 'industria', name: this.$gettext('Industria')},
            {id: 'institucion', name: this.$gettext('Institución')},
            {id: 'horeca', name: this.$gettext('Horeca')},
            {id: 'particular', name: this.$gettext('Particular')},
        ],
        typesTask: [
            {id: 'primera-limpieza', name: this.$gettext('Primera limpieza después obras')},
            {id: 'mantenimiento-semanal', name: this.$gettext('Mantenimiento regular semanal')},
            {id: 'mantenimiento-mensual', name: this.$gettext('Mantenimiento regular mensual')},
            {id: 'unica-limpieza', name: this.$gettext('Única limpieza')},
        ],
        typesMachine: [
            {id: 'fregadora', name: this.$gettext('Fregadora')},
            {id: 'barredora', name: this.$gettext('Barredora')},
            {id: 'moqueta', name: this.$gettext('Moqueta')},
            {id: 'hidrolimpiadora', name: this.$gettext('Hidrolimpiadora')},
            {id: 'abrillantado', name: this.$gettext('Abrillantado')},
        ],
        'calendar': {
        'date': this.$moment().format('YYYY-MM-DD'),
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'DD/MM/YYYY' // 'MMM YYYY'
      },
      'pickupDate': {
        'date': null,
        'shortcuts': false,
        'clearable': false,
        'lang': appConfig.language,
        'format': 'DD/MM/YYYY' // 'MMM YYYY'
      },
      }
    },
    computed: {
      client () {
        return this.$store.getters.client
      },
      center () {
        return this.$store.getters.center
      },
    },
    methods: {
      async finish(){
        try {
          const dataForm = {
            client_name: this.client.name,
            center_name: this.center.name,
            city: this.city,
            province: this.province,
            machinery_use: this.machinery_use,
            client_type: this.client_type,
            work_type: this.work_type,
            machinery_type: this.machinery_type,
            request_date: this.request_date,
            rent_date: this.rent_date,
            rent_days: this.rent_days,
            description: this.description,
          }
          console.log(dataForm)

          const {status, data } = await SrvOrders.sendMachineryMessage(dataForm)
          if (status === 201) {
            setTimeout(() => {
              EventBus.$emit('formularioalquilercompletado', '¡Datos enviados desde el hijo!');
            }, 100);
          }
          /*
          setTimeout(() => {
            EventBus.$emit('formularioalquilercompletado', '¡Datos enviados desde el hijo!');
          }, 100); */
        } catch (e) {
          setTimeout(() => {
              EventBus.$emit('formularioalquilercompletado', '¡Datos enviados desde el hijo!');
            }, 100);
          console.log(e)
        }
        
        
      },
      close () {
        EventBus.$emit('formularioalquilercompletado', '¡Datos enviados desde el hijo!');
        this.$emit('update:show', false)
      }
    },
    watch: {
      show(state) {
        this.syncShow = state
      },
      syncShow(state) {
        this.$emit('update:show', state)
      }
    }
  }
  </script>
  
  <style></style>
  